<template>
  <section>
    <h3 v-if="playerAnswers && teams">Wat de spelers nu op hun bordjes hebben staan: </h3>
    <ul class="playerAnswers" v-if="playerAnswers && teams">
      <Sign v-for="(team, tid) in teams" v-bind:key="tid"
            :team="team"
            :class="playerAnswers[tid] ? '' : 'empty'">
        {{ playerAnswers[tid] ? playerAnswers[tid].answer : "" }}
      </Sign>
    </ul>
  </section>
</template>

<script>


import {mapState} from "vuex";
import Sign from "@/components/elements/Sign";

export default {
  name: "PlayerAnswers",
  components: {Sign},
  computed: {
    ...mapState({
      playerAnswers: state => state.answers,
      game: state => state.game,
      teams: state => state.game.teams,
      scores: state => state.scores,
    })
  }
}
</script>

<style scoped>

ul.playerAnswers {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fill,  minmax(clamp(12ch, 50%, 14ch), 1fr));
  grid-gap: 0.25rem;
}

ul.playerAnswers > li {
  user-select: none;

}

ul.playerAnswers > li > h4 {
  font-size: 0.80em;
}

ul.playerAnswers > li.notYetSubmitted > div.signContent {

}

ul.playerAnswers > li > div.signContent {
  height: 5rem;
  font-size: 0.9em;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, .14), 0 4px 2px -3px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
  border-radius: 0.5rem;
  border: 0.25rem solid gray;
  margin: 0;
  padding: 0;
  word-break: break-all;
  background-color: #e8e8e8;
  color: black;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
}


</style>