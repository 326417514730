<template>
  <main>
    <section>
      <div>
        <SafeButton @click="wipeAnswers" time-out=4000>
          <template v-slot:default>Wis de bordjes van alle spelers</template>
          <template v-slot:warning >Ben je zeker?</template>
        </SafeButton>
      </div>
      <div v-if="game && game.current_question">
        <SafeButton @click="wipeCurrentScores" time-out=4000>
          <template v-slot:default>Wis de score van {{ game.current_question }}</template>
          <template v-slot:warning>Ben je zeker?</template>
        </SafeButton>
      </div>
      <div>
        <h3>Dit verwijdert inschrijvingen, bordjes en scores</h3>
        <SafeButton @click="resetQuiz" time-out=4000>
          <template v-slot:default>Reset de quiz volledig</template>
          <template v-slot:warning>Ben je zeker?</template>
        </SafeButton>
      </div>
      <div>
        <h3>Info</h3>
        <ul class="infoPanel">
          <li><span>Pauze: </span><span>{{ isPaused }}</span></li>
          <li><span># inschrijvingen: </span><span>{{ game.teams ? Object.keys(game.teams).length : 0 }}</span>
          </li>
          <li><span># rondes gespeeld: </span><span>{{ score ? Object.keys(score).length : 0 }}</span></li>
          <li><span># beschreven bordjes: </span><span>{{ answers ? Object.keys(answers).length : 0 }}</span></li>
          <li><span>Laatst verbeterde vraag: </span><span>{{ mostRecentClosedQuestion || '...' }}</span></li>
          <li><span>Huidige vraag: </span><span>{{ currentQuestion || '...' }}</span></li>
          <li><span>Volgende vraag: </span><span>{{ nextQuestion || '...' }}</span></li>
        </ul>
      </div>
    </section>
    <Inschrijvingen></Inschrijvingen>
  </main>
</template>

<script>
import {mapState} from "vuex";
import Inschrijvingen from "@/components/sections/Inschrijvingen";
import SafeButton from "@/components/elements/SafeButton";

export default {
  name: "DangerZone",
  components: {Inschrijvingen, SafeButton},
  methods: {
    wipeAnswers: function () {
      this.$store.dispatch('wipeAnswers')
    },
    resetQuiz: function () {
      this.$store.dispatch('resetQuiz')
      this.$store.dispatch('openCloseSubmissions', false)
      this.$store.dispatch('openCloseEnrollments', false)
      this.$store.dispatch('currentQuestion', "")
    },
    wipeCurrentScores: function () {
      this.$store.dispatch('wipeCurrentScores')
    },
  },
  computed: {
    ...mapState({
      game: state => state.game,
      questions: state => state.config.questions,
      answers: state => state.answers,
      score: state => state.scores,
      currentQuestion: state => state.game.current_question,
      mostRecentClosedQuestion: (state) => {
        if (state.scores) {
          const lastQuestion = Object.keys(state.scores).slice(-1)
          console.log(lastQuestion)
          if (lastQuestion) {
            return lastQuestion[0]
          }
        }
        return ""
      },
    }),
    nextQuestion() {
      return this.questions[this.questions.indexOf(this.mostRecentClosedQuestion) + 1]
    },
    isPaused() {
      return this.currentQuestion === ''
    },
  }
}
</script>

<style scoped>

section > div {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
}

h3 {
  margin-bottom: 1rem;
}

ul {
  max-width: 24rem;
}

li {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

li span {
  vertical-align: bottom;
  align-content: center;
  font-size: inherit;

}

li span:first-child {
  text-align: right;
  margin-right: 1rem;
}

li span:last-child {
  font-weight: bolder;
  text-align: center;

}

button {
  border: none;
  font-family: inherit;
  font-size: 1rem;
  font-weight: bolder;
  border-radius: .66rem;
  padding: 1.5rem 1rem;
  background-image: radial-gradient(circle farthest-corner at 97.4% 91.4%, var(--theme-bright-color) 0%, var(--theme-dark-color) 82.4%);
  color: var(--color);
  text-transform: uppercase;
  box-shadow: 0 0.4rem var(--theme-darker-color), 0 0.5rem 0.1rem rgba(0, 0, 0, 0.50);
  position: relative;
  top: -0.4rem;
  user-select: none;
  text-align: center;
  vertical-align: text-top;
}

button:enabled:active {
  top: 0;
  color: rgba(255, 255, 255, 1);
  box-shadow: 0 0.0rem var(--theme-darker-color), 0 0.1rem rgba(0, 0, 0, 0.4);
}

button:disabled {
  top: 0;
  color: var(--color);
  text-shadow: 0 0 1rem var(--theme-bright-color);
  box-shadow: 0 0.0rem var(--theme-darker-color), 0 0.1rem rgba(0, 0, 0, 0.4);
}

ul.infoPanel > li {
  font-size: 0.8em;
}

</style>