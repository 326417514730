<template>
  <main>
    <h1>Admin Page</h1>

    <p>
      Hallo daar, Bordjesquizmaster <strong>{{ user.email }}</strong>!
      Bovenaan kies je welke view je wilt:
    </p>
    <ul>
      <li>Score toont het scorebord</li>
      <li>Quizmaster laat je toe de vragen te stellen en verbetere</li>
      <li>Jury laat je ook verbeteren, maar toont tevens de spelersbordjes live</li>
      <li>Danger! bevat functies die riskant zijn en je tijdens het spel best niet gebruikt</li>
    </ul>
  </main>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: "Home",
  components: {},
  data: function () {
    return {
      hello: false
    }
  },
  methods: {
    safeClicked: function () {
      alert('gelukt!')
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
    })
  }
}
</script>

<style scoped>
button {
    border: none;
    font-family: inherit;
    font-size: 1.33rem;
    font-weight: bolder;
    border-radius: 0.5rem;
    padding: 0.33rem 0.66rem;
    color: white;
    text-transform: uppercase;
    box-shadow: 0 0.2rem rgba(75, 00, 130, 0.33), 0 0.3rem rgba(0, 0, 0, 0.4);
    position: relative;
    top: 0;
    transition: all 40ms ease-in-out;
    user-select: none;
    background-image: radial-gradient(circle farthest-corner at 97.4% 91.4%, rgb(204, 0, 107) 0%, rgb(117, 0, 99) 82.4%);
}

button:enabled:active {
    top: 0.2rem;
    box-shadow: 0 0.05rem rgba(75, 00, 130, 0.33), 0 0.2rem rgba(0, 0, 0, 0.4);
}


button:disabled {
    top: 0.2rem;
    color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0.0rem rgba(75, 00, 130, 0.33), 0 0.1rem rgba(0, 0, 0, 0.4);
}
</style>