<template>
  <section>
    <h3>Analyse</h3>
    <select v-model="showOnly">
      <option :value="(entry) => correctAnswersForRound(entry) === 0">QMS</option>
      <option :value="(entry) => correctAnswersForRound(entry) === 1">Solo</option>
      <option :value="(entry) => correctAnswersForRound(entry) === 2">Duo</option>
      <option :value="(entry) => correctAnswersForRound(entry) === Object.keys(teams).length">Full House</option>
      <option :value="undefined">All</option>
    </select>
    <ul class="container">
      <li>
        <ul class="header">
          <li style="order: 0">
            <strong></strong>
          </li>
          <li v-for="(team, team_id, index) in teams" v-bind:key="team_id" :title="team">
            {{ emoji[index] }}
            <span class="teamName">{{ team }}</span>
          </li>
        </ul>
      </li>
      <li v-for="(round, round_id) in scores" v-bind:key="round_id" class="scores">
        <ul class="answers" v-show="!filtered(round_id)">
          <li style="order: 0">
            <strong>{{ round_id }}</strong> ({{ correctAnswersForRound(round_id) }}/{{ Object.keys(teams).length }})
          </li>
          <li v-for="(team, team_id) in teams" v-bind:key="team_id" :title="round[team_id].answer || '🤷'"
              :class="round.hasOwnProperty(team_id) && round[team_id].correct? 'correctAnswer' : 'incorrectAnswer'">
          </li>
        </ul>
      </li>
    </ul>
  </section>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Analysis",
  data: function () {
    return {
      showOnly: undefined,
      emoji: ['🐵', '🐶', '🐺', '🦊', '🦝', '🐱', '🦁', '🐯', '🐴', '🦄',
        '🦓', '🐮', '🐷', '🐗', '🐭', '🐹', '🐰', '🐔', '🐸', '🕷️',]
    }
  },
  methods: {
    correctAnswersForRound: function (round) {
      const answers = this.scores[round] || [];
      return Object.values(answers).filter((entry) => entry.correct === true).length
    },
    filtered: function (round_id) {
      return this.showOnly !== undefined && !this.showOnly(round_id)
    }
  },
  computed: {
    ...mapState({
      scores: state => state.scores ? state.scores : {},
      teams: state => state.game.teams,
    })
  }
}
</script>

<style scoped>
span.teamName {
  font-size: 0.75ch;
  word-break: break-all;
  display: none;
}

ul.container:hover span.teamName {
  display: grid;
}

section > ul {
  display: grid;
}

ul.answers, ul.header {
  display: grid;
  grid-template-columns: 12rem repeat(20, 3ch);
}

ul.header > *{
  display: flex; flex-flow: column;
}

ul.answers li:first-child {
  font-size: 1.25rem;
}

li.correctAnswer > span, li.incorrectAnswer > span {
  display: none;
}

li.correctAnswer {
  background-color: #2a2a2a;
}

li.incorrectAnswer {
  background-color: #2a2a2a;
}

li.correctAnswer:before {
  content: '✔️';
}

li.incorrectAnswer:before {
  content: '❌';
}

li.scores:hover > ul.answers li:first-child:hover {
  text-decoration: underline;
}

li.scores:hover > ul li.correctAnswer > span,
li.scores:hover > ul li.incorrectAnswer > span {
  display: inline;
}
</style>