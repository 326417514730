<template>
  <section class="score">

    <h2>Scoreboard</h2>
    <ul class="scoreboard">
      <li>
        <h4>Speler</h4>
        <h4>Score</h4>
        <h4>✓</h4>
      </li>
      <li v-for="(entry, index) in fbsScore" v-bind:key="index" class="scores">
        <div>{{ teams[entry.team] }}</div>
        <div>{{ parseFloat(entry.fbs).toFixed(3) || 0 }}</div>
        <div>{{ entry.one || 0 }}</div>
      </li>
    </ul>

  </section>
</template>

<script>
import _ from 'lodash';
import {mapState} from 'vuex'

export default {
  name: "ScoreBoard",
  beforeMount() {
    this.$store.dispatch('bindScoresRef');
  },
  computed: {
    ...mapState({
      scores: state => state.scores ? state.scores : {},
      teams: state => state.game.teams,
      currentQuestion: state => state.game.current_question,

      scoreBoard: state => {

        let fb = {}
        let one = {}

        let aantalTeams = 0
        if (state.game && state.game.teams) {
           aantalTeams = Object.keys(state.game.teams).length
        }

        for (let question in state.scores) {
          // console.log("tallying", question)

          // Count Correct Answers
          let correctAnswers = 0
          for (const team in state.scores[question]) {
            const teamAnswer = state.scores[question][team]
            if (teamAnswer && teamAnswer.correct) {
              correctAnswers = correctAnswers + 1;
            }
          }

          // update team scores
          for (const team in state.game.teams) {
            if (fb[team] === undefined) {
              fb[team] = 0
            }
            if (one[team] === undefined) {
              one[team] = 0
            }

            const teamAnswer = state.scores[question][team]
            if (teamAnswer && teamAnswer.correct) {
              fb[team] = fb[team] + (aantalTeams / correctAnswers);
              one[team] = one[team] + 1
            }
          }
        }

        let allScores = []
        for (const team in state.game.teams) {
          allScores.push({
            "team": team,
            "fbs": fb[team] || 0,
            "one": one[team]
          })
        }
        return allScores
      },
      fbsScore() {
        return _.orderBy(this.scoreBoard, ['fbs'], ['desc'])
      },
      oneScore() {
        return _.orderBy(this.scoreBoard, ['one'], ['desc'])
      }
    })
  }
}
</script>

<style scoped>

h4 {
  text-align: center;
}

section.score {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 2rem;
  row-gap: 1rem;

  background: rgba(0, 0, 0, 0.75);
  border-radius: 1rem;
  padding: 1rem;
  font-family: Courier, "Droid Sans Mono", monospace;


}

section.score > ul.scoreboard {
  width: max(18rem, min(80vw, 48rem));
}

section.score > ul.scoreboard > li {
  display: grid;
  grid-template-columns: 3fr 1.5fr 1.5fr;
  padding: 0.25rem 1rem;
}

section.score > ul.scoreboard > li:not(:first-child):hover {
  font-weight: 700;
  background-color: rgba(255,255,255,0.1);
}

section.score > ul.scoreboard > li:first-child * {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  text-align: right;
  text-decoration: none;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

section.score > ul.scoreboard > li:first-child h4 {
  white-space: nowrap;
  font-weight: bolder;
}

section.score > ul.scoreboard > li:first-child h4:first-child {
  text-align: left;
}


section.score > ul.scoreboard > li > div:first-child {
  text-align: left;
}

section.score > ul.scoreboard > li {
  text-align: right;
  padding-right: 1rem;
}


</style>