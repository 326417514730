<template>
  <section>
    <h3>Verbeteren</h3>
    <slot></slot>
    <ul v-if="scores">
      <Sign v-for="(team, tid) in game.teams" v-bind:key="tid"
            v-on:input="correctQuestion($event, scores, tid)"
            v-bind:checked="scores[tid] && scores[tid].correct"
            :team="team"
            :class="[scores[tid] && scores[tid].correct ? 'correct' : 'incorrect']"
            :disabled="!scores[tid]">
        {{ scores[tid] ? scores[tid].answer : '' }}
      </Sign>
    </ul>
    <form>
      <label>
        <Toggle v-model="autocorrect"></Toggle>
        Automatisch identieke antwoorden verbeteren?
      </label>
    </form>
  </section>
</template>

<script>

import {mapState} from 'vuex'
import Sign from "@/components/elements/Sign";
import Toggle from "@/components/elements/Toggle";

export default {
  name: "Correction",
  components: {Toggle, Sign},
  data: function () {
    return {
      autocorrect: true
    }
  },
  props: {
    question: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      game: state => state.game,
      scores: function (state) {
        return state.scores[this.question]
      }
    }),
  },
  methods: {
    correctQuestion: function (e, s, t) {
      const thisTeamAnswer = s[t];
      let payload = {
        [this.question]: {}
      }
      if (this.autocorrect) {
        // We want the answer to be counted as correct
        // for _all_ the teams that have the same answer
        for (const [tid, otherTeamAnswer] of Object.entries(this.scores)) {
          if (otherTeamAnswer && otherTeamAnswer.answer === thisTeamAnswer.answer) {
            payload[this.question][tid] = {
              // This code keeps the original data,
              // But replaced `correct` with the user input
              ...otherTeamAnswer,
              correct: e.target.checked
            }
          }
        }
      } else {
        // Just correct this one answer
        payload[this.question][t] = {
          // This code keeps the original data,
          // But replaced `correct` with the user input
          ...thisTeamAnswer,
          correct: e.target.checked
        }
        console.log(payload)
      }

      this.$store.dispatch('correctAnswers', payload)
    }
  },
}
</script>

<style scoped>
ul {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(clamp(12ch, 50%, 14ch), 1fr));
  grid-gap: 0.25rem;
}
</style>