<template>
  <main>
    <GameControls></GameControls>
  </main>
</template>

<script>
import GameControls from "@/components/sections/GameControls";

export default {
  name: "QuizMaster",
  components: {
    GameControls,
  },
}
</script>

<style scoped>
</style>