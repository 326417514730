<template>
  <main>
    <ScoreBoard></ScoreBoard>
  </main>
</template>

<script>
import ScoreBoard from "@/components/sections/ScoreBoard";

export default {
  name: "Score",
  components: {
    ScoreBoard,
  },
}
</script>

<style scoped>

</style>