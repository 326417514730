<template>
  <section>
    <div class="enrollmentToggle">
      <h3>Kan men zich inschrijven?</h3>
      <Toggle v-model="openCloseEnrollments"></Toggle>
    </div>
    <div>
      <h3>Ingeschreven teams</h3>
      <ol>
        <li v-for="(team, tid) in teams" v-bind:key="tid">
          <a @click="disenrollTeam(tid)">{{ team }}</a>
        </li>
      </ol>
    </div>
  </section>
</template>

<script>
import {mapState} from "vuex";
import Toggle from "@/components/elements/Toggle";

export default {
  name: "Inschrijvingen",
  components: {Toggle},
  methods: {
    disenrollTeam: function (v) {
      // console.log(v)
      this.$store.dispatch('disenrollTeam', {teamId: v})
    },

  },
  computed: {
    ...mapState({
      teams: state => state.game.teams,
      scoreboard(state) {
        return state.scores ? state.scores[state.game.current_question] : null
      },
      currentQuestion(state) {
        return state.game.current_question
      },
    }),
    openCloseEnrollments: {
      get() {
        return this.$store.state.game.open_for_enrollment
      },
      set(value) {
        this.$store.dispatch('openCloseEnrollments', value)
      }
    },
  },
}
</script>

<style scoped>
div {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
}

@media screen and (min-width: 36rem) {
  div.enrollmentToggle {
    grid-template-columns: 1fr 1fr;
  }
}

ol {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
}

li {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 1rem;
  border-radius: 0.5rem;
  background-color: rgba(0, 0, 0, 0.25);
  padding: 0.5rem;
  place-items: center;
}

li:hover {
  text-decoration: line-through;
}

a:hover {
  text-decoration: none;
}

/*Toggle*/
input[type=checkbox] {
  font-size: 1.5rem;
  font-weight: bold;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
}

label.toggle {
  text-align: center;
  font-weight: bold;
  border-radius: 1.5rem;
  background-color: rgba(102, 02, 060, 0.33);
  column-gap: 1rem;
  font-size: 1.5rem;
}

label.toggle:last-child {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
}

label.toggle > span {
  border: 0.2rem solid #66023c;
  border-radius: 1.5rem;
  background-color: #66023c;
  color: white;
  top: 0.2rem;
  box-shadow: 0 0.2rem rgba(102, 02, 060, 0.33), 0 0.3rem rgba(0, 0, 0, 0.4);

}

label.toggle > span:first-child {
  grid-column: 1;
}

label.toggle > span:last-child {
  grid-column: 2;
}
</style>