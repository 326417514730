<template>
  <main>
    <span>meest recent opgehaalde vraag: <strong class="highlight">{{ mostRecentClosedQuestion }}</strong>,
      vraag die nu gesteld wordt: <strong class="highlight">{{ currentQuestion }}</strong></span>
    <PlayerAnswers></PlayerAnswers>
    <Correction :question="question">
      <form>
        <label>
          Kies de vraag die je wilt verbeteren:
          <select v-model="question" :disabled="follow">
            <option v-for="(q) in config.questions" v-bind:key="q" v-bind:value="q">
              {{ q }}
            </option>
          </select>
        </label>
        <label>
          Meevolgen?
          <Toggle v-model="follow"></Toggle>
        </label>
      </form>
    </Correction>
  </main>
</template>

<script>

import Correction from "@/components/sections/Correction";
import PlayerAnswers from "@/components/sections/PlayerAnswers";
import {mapState} from "vuex";
import Toggle from "@/components/elements/Toggle";

export default {
  name: "Jury",
  data: function () {
    return {
      question: "",
      follow: true,
    }
  },
  mounted() {
    this.question = this.currentQuestion
  },
  watch: {
    follow: function (value) {
      if (value) {
        this.question = this.mostRecentClosedQuestion
      }
    },
    mostRecentClosedQuestion: function (value) {
      console.log('current changed', value)
      if (this.follow) {
        this.question = this.mostRecentClosedQuestion
      }
    }
  },
  components: {
    Toggle,
    PlayerAnswers,
    Correction,
  },
  computed: {
    ...mapState({
      config: state => state.config,
      currentQuestion: state => state.game ? state.game.current_question : '',
      mostRecentClosedQuestion: (state) => {
        if (state.scores) {
          const lastQuestion = Object.keys(state.scores).slice(-1)
          console.log(lastQuestion)
          if (lastQuestion) {
            return lastQuestion[0]
          }
        }
        return ""
      },
    })
  }
}
</script>

<style scoped>

select {
  appearance: none;
  outline: none;
  text-align: right;

  padding-right: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-transform: uppercase;

  border: 0.2rem solid var(--theme-bright-color);
  border-radius: 0.2rem;

  background-color: var(--theme-dark-color);
  box-shadow: 0 0.0rem var(--theme-darker-color), 0 0.1rem rgba(0, 0, 0, 0.4);
  top: 0;
}

select > option {
  text-transform: uppercase;
  text-align: right;
  padding-right: 1rem;
  background-color: var(--background-color);
}
select:disabled {
  color: gray;
}

</style>