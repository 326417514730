<template>
  <button @click="safeClick">
    <slot v-if="!preClickDone"></slot>
    <slot v-else name="warning"></slot>
    <span v-if="preClickDone" >{{ ((timeOut - msSinceClick) / 1000).toFixed(2) }}</span>
  </button>
</template>

<script>
export default {
  name: "SafeButton",
  props: {
    timeOut: {
      type: Number,
      default: 3000
    }
  },
  data: function () {
    return {
      preClickDone: false,
      preClickDoneStamp: null,
      preClickChecker: null,
      msSinceClick: 0
    }
  },
  mounted: function () {
    this.resetButton()
  },
  methods: {
    resetButton: function () {
      // reset
      this.preClickDone = false
      this.preClickDoneStamp = null
      this.preClickChecker = null
      this.msSinceClick = 0
    },
    cancelClick: function () {
      this.msSinceClick = Date.now() - this.preClickDoneStamp
      if (this.msSinceClick > this.timeOut) {
        clearInterval(this.preClickChecker)
        this.resetButton()
      }
    },
    safeClick: function (e) {
      e.stopPropagation()
      if (this.preClickDone) {
        clearInterval(this.preClickChecker)
        this.$emit('click', e)
        this.resetButton()
      } else {
        // setup
        this.preClickDone = true
        this.preClickDoneStamp = Date.now()
        this.preClickChecker = setInterval(this.cancelClick.bind(this), 100)
      }
    }
  }
}
</script>

<style scoped>
  button {
    display: grid;
    grid-template-columns: 4fr 1fr;
  }
</style>