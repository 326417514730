<template>
  <li>
    <h4>{{ team }}</h4>
    <label>
      <input type="checkbox"
             :disabled="disabled"
             v-bind:checked="checked"
             v-on:input="$emit('input', $event)">
      <slot></slot>
    </label>
  </li>
</template>

<script>
export default {
  name: "Sign",
  model: {
    prop: 'checked',
    event: 'input'
  },
  props: {
    team: String,
    checked: Boolean,
    disabled: Boolean
  }
}
</script>

<style scoped>

li {
  user-select: none;
}

li > h4 {
  font-size: 0.85rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

label {
  height: 5rem;
  font-size: 1rem;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, .14), 0 4px 2px -3px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
  border-radius: 0.5rem;
  border: 0.25rem solid gray;
  margin: 0;
  padding: 0;
  word-break: break-word;
  background-color: #e8e8e8;
  color: black;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  text-align: center;
}

li.correct label {
  background-color: darkgreen;
  font-weight: bold;
  color: white;
}

li.incorrect label {
  background-color: darkred;
  font-weight: bold;
  color: white;
}

li.empty label {
  background: repeating-linear-gradient(
      45deg,
      rgba(255, 0, 0, 0.6),
      rgba(255, 0, 0, 0.6) 10px,
      rgba(255, 255, 255, 0.9) 10px,
      rgba(255, 255, 255, 0.9) 20px
  );
  font-weight: bold;
  color: white;
}

li.empty label:after {
  content: "🤷";
  font-size: 2rem;
}

input[type=checkbox] {
  display: none;
}
</style>