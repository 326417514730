<template>
  <main>
    <section>
      <div id="firebaseui-auth-container">
      </div>
    </section>
  </main>
</template>

<script>
import firebase from "firebase/app";
import * as firebaseui from 'firebaseui';
import "firebaseui/dist/firebaseui.css";

export default {
  name: "Login",
  data: function () {
    return {}
  },
  mounted() {
    let ui = firebaseui.auth.AuthUI.getInstance();
    if (!ui) {
      ui = new firebaseui.auth.AuthUI(firebase.auth());
    }
    const uiConfig = {
      signInSuccessUrl: '/',
      signInOptions: [{
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      }],
      // Other config options...
    };
    if (ui.isPendingRedirect()) {
      ui.start('#firebaseui-auth-container', uiConfig);
    }
    if (!(this.$store.state.user && this.$store.state.user.uid)) {
      ui.start('#firebaseui-auth-container', uiConfig)
    }

  },
  //   computed: mapState({
  //   user: state => state.user,
  // }),
  methods: {}
}
</script>

<style scoped>

</style>