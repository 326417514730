import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'

import App from './App.vue'

import {vuexfireMutations, firebaseAction} from 'vuexfire'
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

import Login from "@/components/Login";
import Jury from "@/components/Jury";
import Analyse from "@/components/Analyse";
import QuizMaster from "@/components/QuizMaster";
import Home from "@/components/Home";
import DangerZone from "@/components/DangerZone";
import Score from "@/components/Score";


Vue.use(Vuex);
Vue.use(VueRouter)

firebase.initializeApp({
    apiKey: "AIzaSyDbCe6GJEqSOenCmpkCunDbiV8tSI_xp5k",
    authDomain: "frebee-7b2d8.firebaseapp.com",
    databaseURL: "https://frebee-7b2d8-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "frebee-7b2d8",
    storageBucket: "frebee-7b2d8.appspot.com",
    messagingSenderId: "730905147326",
    appId: "1:730905147326:web:4ba88c042cc2974841ebef"
});

const db = firebase.database()

const store = new Vuex.Store({
    // setup the reactive todos property
    state: {
        scores: {},
        game: {
            team: {}
        },
        answers: {},
        config: {},
        user: null,
    },

    mutations: {
        ...vuexfireMutations,
        UPDATE_USER(state, payload) {
            state.user = payload
        },
    },
    actions: {
        bindConfigRef: firebaseAction(context => {
            return context.bindFirebaseRef('config', db.ref('config'))
        }),
        unbindConfigRef: firebaseAction(({unbindFirebaseRef}) => {
            unbindFirebaseRef('config')
        }),

        bindScoresRef: firebaseAction(context => {
            return context.bindFirebaseRef('scores', db.ref('scores'))
        }),
        unbindScoresRef: firebaseAction(({unbindFirebaseRef}) => {
            unbindFirebaseRef('scores')
        }),

        bindGameRef: firebaseAction(context => {
            return context.bindFirebaseRef('game', db.ref('game'))
        }),
        unbindGameRef: firebaseAction(({unbindFirebaseRef}) => {
            unbindFirebaseRef('game')
        }),

        bindAnswerRef: firebaseAction(context => {
            return context.bindFirebaseRef('answers',
                db.ref('answers'))
        }),
        unbindAnswerRef: firebaseAction(({unbindFirebaseRef}) => {
            unbindFirebaseRef('answers')
        }),

        async openCloseSubmissions(context, payload) {
            return db.ref('game')
                .update({open_for_submission: payload})
                .then(() => {
                    console.log(`open_for_submission is now: "${payload}"`)
                })
        },
        async openCloseEnrollments(context, payload) {
            return db.ref('game')
                .update({open_for_enrollment: payload})
                .then(() => {
                    console.log(`enrollment is now: "${payload}"`)
                })
        },
        async currentQuestion(context, payload) {
            return db.ref('game')
                .update({current_question: payload})
                .then(() => {
                    console.log(`current_question is now: "${payload}"`)
                })
        },
        async correctAnswers(context, payload) {
            for (const [key, value] of Object.entries(payload)) {
                db.ref(`scores/${key}`)
                    .update(value)
                    .then(() => {
                        console.log(`scores updated`)
                    })
            }
        },
        async wipeAnswers() {
            return db.ref('answers')
                .remove()
                .then(() => {
                    console.log(`answers wiped`)
                })
        },
        async disenrollTeam(context, payload) {
            console.log(payload)
            return db.ref(`game/teams/${payload.teamId}`)
                .remove()
                .then(() => {
                    console.log(`team disenrolled`)
                })
        },
        async wipeCurrentScores(context) {
            const cq = context.state.game.current_question;
            return db.ref(`scores/${cq}`)
                .remove()
                .then(() => {
                    console.log(`score of ${cq} wiped`)
                })
        },
        async resetQuiz() {
            db.ref('answers')
                .remove()
                .then(() => {
                    console.log(`answers wiped`)
                });
            db.ref('scores')
                .remove()
                .then(() => {
                    console.log(`scores wiped`)
                });
            db.ref('game/teams')
                .remove()
                .then(() => {
                    console.log(`enrollments wiped`)
                });

        },
        async collectAnswers() {
            const currentQuestion = store.state.game.current_question
            const currentAnswers = store.state.answers

            let blankAnswers = {}
            for (const team of Object.keys(store.state.game.teams)) {
                blankAnswers[team] = {
                    correct: false,
                    answer: ""}
            }

            let payload = {}
            payload[currentQuestion] = {
                ...blankAnswers,
                ...currentAnswers
            }

            return db.ref('scores')
                .update(payload)
                .then(() => {
                    console.log(`scores updated`)
                })
        },
        async signOut({commit}) {
            commit('UPDATE_USER', null)
        },
        async signIn({commit}, payload) {
            commit('UPDATE_USER', payload)
        }
    }
})

const routes = [
    {path: '/', component: Home, name: 'home'},
    {path: '/dangerzone', component: DangerZone, name: 'dangerZone'},
    {path: '/jury', component: Jury, name: 'jury'},
    {path: '/analyse', component: Analyse, name: 'analyse'},
    {path: '/score', component: Score, name: 'score'},
    {path: '/login', component: Login, name: 'login'},
    {path: '/quizmaster', component: QuizMaster, name: 'quizMaster'},
]
const router = new VueRouter({
    routes // short for `routes: routes`
})

new Vue({
    store: store,
    router: router,
    render: h => h(App),
}).$mount('#app')
