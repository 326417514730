<template>
  <div id="app">
    <nav>
      <router-link to="/">Home</router-link>
      <router-link to="/score">Score</router-link>
      <router-link to="/quizmaster">QuizMaster</router-link>
      <router-link to="/jury">Jury</router-link>
      <router-link to="/analyse">Analyse</router-link>
      <router-link to="/dangerzone">Danger!</router-link>
    </nav>

    <router-view></router-view>

    <footer>
      <router-link to="/login" v-if="$store.state.user === null">Login</router-link>
      <a @click="signOut" v-else>Log out {{ $store.state.user.email }}</a>
    </footer>
  </div>
</template>

<script>

import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  name: 'App',
  created() {
    firebase.auth().onAuthStateChanged(user => {
      this.$store.state.user = user;
      if (user && user.uid) {
        this.$store.dispatch('bindScoresRef');
        this.$store.dispatch('bindGameRef');
        this.$store.dispatch('bindConfigRef');
        this.$store.dispatch('bindAnswerRef');
      } else {
        this.$store.dispatch('unbindScoresRef');
        this.$store.dispatch('unbindGameRef');
        this.$store.dispatch('unbindConfigRef');
        this.$store.dispatch('unbindAnswerRef');
      }
    });
  },

  methods: {
    signOut(e) {
      e.stopPropagation();
      this.$store.dispatch('signOut')
      firebase.auth().signOut();
      this.$router.push({
        name: "Login"
      });
    }
  },
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3rem auto 3rem;
  row-gap: 2rem;
  min-height: 100vh;

  justify-items: center;
}

#app > footer {
  width: 100vw;
  background-image: radial-gradient(circle farthest-corner at 97.4% 91.4%, var(--theme-dark-color) 0%, var(--theme-bright-color) 82.4%);

  padding: 0.5rem 3.5rem;
  text-align: right;
}

#app > nav {
  width: 100vw;
  background-image: radial-gradient(circle farthest-corner at 97.4% 91.4%, var(--theme-bright-color) 0%, var(--theme-dark-color) 82.4%);
  padding: 0.5rem 3.5rem;

  display: grid;
  grid-template-columns: repeat(7, 1fr);
  place-content: center;
  grid-gap: 1rem;

  box-shadow: 0 0 0.5rem black;
}


#app > nav a {
  text-decoration: none;
  font-size: clamp(0.9rem, 2.5vw, 1.5rem);
  color: var(--color);
}

#app > nav a:hover {
  text-decoration: underline;
}

#app > main {
  max-width: 48rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  padding: 0 1.5rem;

}

#app > main > section {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;
  place-content: start;
}

</style>
