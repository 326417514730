<template>
  <section>
    <form v-if="questions && game && score">
      <button @click="updateQuestions" id="pauseButton"
              value=""
              :disabled="currentQuestion === ''">
      </button>
      <span class="timer">{{ msToTime(questionOpenFor) }}</span>

      <button @click="goToNextQuestion" id="nextButton"
              v-if="nextQuestion"
              :disabled="nextQuestion === currentQuestion">
        Stel {{ nextQuestion }}
      </button>

      <button style="padding: 2rem 1rem" id="ophaalButton"
              v-if="!isPaused"
              :disabled="nextQuestion !== currentQuestion"
              @click="collectAnswers">

        <span v-if="nextQuestion !== currentQuestion"
        >Verbeter {{ mostRecentClosedQuestion }}</span>
        <span v-else
        >Haal {{ nextQuestion }} Op</span>
      </button>
      <Correction style="grid-area: verbeter"
                  :question="mostRecentClosedQuestion"
                  v-if="!isPaused && (currentQuestion !== nextQuestion)"
      ></Correction>
    </form>
  </section>
</template>

<script>

import {mapState} from "vuex";
import Correction from "@/components/sections/Correction";


export default {
  name: "GameControls",
  data: function () {
    return {
      questionOpenSince: Date.now(),
      questionOpenFor: 0,
    }
  },
  components: {Correction},
  methods: {
    msToTime: function (ms) {
      return new Date(ms).toISOString().slice(11, -5);
    },
    collectAnswers: function () {
      this.$store.dispatch('collectAnswers')
      this.openForSubmission = false;
    },
    wipeAnswers: function () {
      this.$store.dispatch('wipeAnswers')
    },
    goToNextQuestion: function () {
      this.$store.dispatch('currentQuestion', this.nextQuestion)
      this.openForSubmission = this.game.currentQuestion !== "";
    },
    updateQuestions: function (event) {
      this.$store.dispatch('currentQuestion', event.target.value)
      this.openForSubmission = event.target.value !== "";
    }
  },
  computed: {
    ...mapState({
      questions: state => state.config.questions,
      game: state => state.game,
      score: state => state.scores,
      answers: state => state.answers,
      currentQuestion: state => state.game.current_question,
      mostRecentClosedQuestion: (state) => {
        if (state.scores) {
          const lastQuestion = Object.keys(state.scores).slice(-1)
          console.log(lastQuestion)
          if (lastQuestion) {
            return lastQuestion[0]
          }
        }
        return ""
      },
    }),
    nextQuestion() {
      return this.questions[this.questions.indexOf(this.mostRecentClosedQuestion) + 1]
    },
    isPaused() {
      return this.currentQuestion === ''
    },
    openForSubmission: {
      get() {
        return this.$store.state.game.open_for_submission
      },
      set(value) {
        this.$store.dispatch('openCloseSubmissions', value)
      }
    },
  },
  watch: {
    currentQuestion: function (value) {
      console.log('current changed', value)
      this.questionOpenSince = Date.now()
      clearInterval(this.questionUpdateInterval);
      this.questionUpdateInterval = setInterval((scope) => scope.questionOpenFor = Date.now() - scope.questionOpenSince, 1000, this);
    }
  },
  beforeDestroy() {
    clearInterval(this.questionUpdateInterval);
  },
}
</script>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');

form {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-areas: "pause timer"
                       "next next"
                       "ophaal ophaal"
                       "verbeter verbeter";
  grid-gap: 1rem;
  margin-bottom: 2rem;
}

button {
  border: none;
  font-family: inherit;
  font-size: 1.33rem;
  font-weight: bolder;
  border-radius: .66rem;
  padding: 0.33rem 0.66rem;
  background-color: var(--theme-dark-color);
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
  box-shadow: 0 0.4rem var(--theme-darker-color), 0 0.5rem 0.1rem rgba(0, 0, 0, 0.50);
  position: relative;
  /*transition: box-shadow 350ms ease-in, text-shadow 350ms ease-in;*/
  top: -0.4rem;
  user-select: none;
}

button:enabled {
  color: rgba(255, 255, 255, 0.75);
}

button:enabled:active {
  top: 0;
  color: rgba(255, 255, 255, 1);
  box-shadow: 0 0.0rem var(--theme-darker-color), 0 0.1rem rgba(0, 0, 0, 0.4);
}

button:disabled {
  top: 0;
  color: rgba(255, 255, 255, 1);
  background-color: var(--theme-bright-color);

  text-shadow: 0 0 1rem var(--theme-darker-color);
  box-shadow: 0 0.0rem var(--theme-darker-color), 0 0.1rem rgba(0, 0, 0, 0.4);
}

button#pauseButton {
  grid-area: pause;

  background-image: url('../../assets/pause.png');
  background-size: 2rem 2rem;
  background-repeat: no-repeat;
  background-position: center;
  height: 3rem;
}

button#nextButton {
  /*background-image: url('../../assets/next.png');*/
  grid-area: next;

  background-size: 2rem 2rem;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
  background-origin: content-box;
  padding: 1rem 2rem;
}

button#ophaalButton {
  grid-area: ophaal;
}

input[type=checkbox] {
  font-size: 1.5rem;
  font-weight: bold;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
}


#nextButton {
  grid-area: stel;
}

span.timer {
  grid-area: timer;

  display: flex;
  height: 3rem;
  font-size: 1.5rem;
  font-family: 'Orbitron', sans-serif;
  align-items: center;
  justify-content: space-around;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  border-radius: 0.25rem;
  text-align: center;
  border: 0.2rem solid var(--theme-darker-color);
  color: var(--theme-bright-color)
}
</style>