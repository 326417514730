<template>
  <main>
    <Analysis></Analysis>
  </main>
</template>

<script>
import Analysis from "@/components/sections/Analysis";

export default {
  name: "Analyse",
  components: {
    Analysis,
  },
}
</script>

<style scoped>

</style>