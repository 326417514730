<template>
  <label>
    <input type="checkbox" id="myCheckbox"
           v-bind:checked="checked"
           v-on:change="$emit('change', $event.target.checked)">
    <span class="container">
      <span class="nubbie">&nbsp;</span>
    </span>
    <slot></slot>
  </label>
</template>

<script>
export default {
  name: "Toggle",
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: Boolean
  },
}
</script>

<style scoped>

label {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
}

span.container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 2em;
  width: 4em;
  border-radius: 1em;
  padding: 0.5em;
  margin-right: 1em;
  background-image: radial-gradient(circle farthest-corner at 97.4% 91.4%, var(--theme-darker-color) 0%, var(--theme-dark-color) 82.4%);
  box-shadow: -0.1em 0.1em 0.05em 0.01em rgba(0, 0, 0, 0.5) inset;
  transition: background-color 50ms ease-in-out;
}

span.nubbie {
  width: 1.5em;
  height: 1.5em;
  border: 0.17em outset var(--theme-dark-color);
  border-radius: 1em;
  padding: 0.5em;
  background: radial-gradient(circle, rgba(255, 255, 255, 1) 10%, rgba(245, 245, 245, 1) 40%, rgba(218, 218, 218, 1) 100%) fixed;
  background-size: contain;
  box-shadow: -0.1em 0.1em 0.05em 0.01em rgba(0, 0, 0, 0.5),
  0.01em 0.05em 0 rgba(128, 128, 128, 1);

}

input[type=checkbox]:checked ~ span.container {
  justify-content: flex-end;
}

input[type=checkbox]:checked ~ span.container > span.nubbie {
  justify-content: flex-end;

}

input[type=checkbox]:checked + span {
  background-image: radial-gradient(circle farthest-corner at 97.4% 91.4%, var(--theme-dark-color) 0%, var(--theme-bright-color) 82.4%);
}

input[type=checkbox] {
  display: none;
}
</style>